import {rootApi} from '..';
import { objectToParamString } from '../../../utils/params';
import { 
  GenerationPostParams,
  GenerationsGetParams, 
  PrintPostParams, 
  ReceivePostParams, 
  TagsGetParams 
} from './types/request';
import { 
  GetGenerationsResponse, 
  GetTagsResponse, 
  PrintGenerationsResponse, 
  ReceiveGenerationsResponse
} from './types/response';

const adminApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getGenerations: build.query<GetGenerationsResponse, GenerationsGetParams>({
      query: (params) => {
        const p = {limit: 10, offset: 0, ...params};
        return ({
          url: `/admin/generate${
            typeof(p) == 'object' 
            ? objectToParamString(p)
            : p}`,
        })
      },
    }),
    generateTag: build.mutation<void, GenerationPostParams>({
      query: (params) => {
        return ({
          url: '/admin/generate',
          data: params,
          method: 'POST'
        })
      },
    }),
    printTag: build.mutation<PrintGenerationsResponse, PrintPostParams>({
      query: (params) => {
        return ({
          url: '/admin/print',
          data: params,
          method: 'POST'
        })
      },
    }),
    receiveTag: build.mutation<ReceiveGenerationsResponse, ReceivePostParams>({
      query: (params) => {
        return ({
          url: '/admin/receive',
          data: params,
          method: 'POST'
        })
      },
    }),
    getTags: build.query<GetTagsResponse, TagsGetParams>({
      query: (params) => {
        const p = {limit: 10, ...params};
        return ({
          url: `/admin/tags${
            typeof(p) == 'object' 
            ? objectToParamString(p)
            : p}`,
        })
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTagsQuery,
  useLazyGetGenerationsQuery,
  usePrintTagMutation,
  useReceiveTagMutation,
  useGenerateTagMutation,
} = adminApi;
