import React from 'react';
import block from 'bem-cn-lite';
import {Button} from '@gravity-ui/uikit';

import './TagStatusGroupButton.scss';
import { TagStatus } from '../../../../store/api/admin/types/models';

const b = block('tag-status-group-btn');

interface TagStatusGroupButtonProps {
  status: TagStatus[];
  onChange: (status: TagStatus[]) => void;
}

export const TagStatusGroupButton: React.FC<TagStatusGroupButtonProps> = ({
  status,
  onChange
}) => {
  return (
    <div className={b()}>
      <Button
        view="flat"
        selected={status.includes(TagStatus.CREATED)}
        onClick={() => onChange([TagStatus.CREATED])}
      >
        Создан
      </Button>
      <Button
        view="flat"
        selected={status.includes(TagStatus.PRINTING)}
        onClick={() => onChange([TagStatus.PRINTING])}
      >
        На печати
      </Button>
      <Button
        view="flat"
        selected={status.includes(TagStatus.STORED)}
        onClick={() => onChange([TagStatus.STORED])}
      >
        На хранении
      </Button>
      <Button
        view="flat"
        selected={status.includes(TagStatus.DEPOSITED)}
        onClick={() => onChange([TagStatus.DEPOSITED])}
      >
        Выдан
      </Button>
      <Button
        view="flat"
        selected={status.includes(TagStatus.INACTIVE)}
        onClick={() => onChange([TagStatus.INACTIVE])}
      >
        Не активен
      </Button>
      <Button
        view="flat"
        selected={status.includes(TagStatus.EXPIRED)}
        onClick={() => onChange([TagStatus.EXPIRED])}
      >
        Просрочен
      </Button>
    </div>
  );
};
