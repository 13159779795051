import React from 'react';
import block from 'bem-cn-lite';

import './GenManage.scss';
import { Layout } from '../../components/layout';
import { GenManageCard } from '../../components/gen-manage-card';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useLazyGetGenerationsQuery, usePrintTagMutation, useReceiveTagMutation } from '../../store/api/admin';
import { Icon, useToaster } from '@gravity-ui/uikit';
import { CircleXmarkFill } from '@gravity-ui/icons';
import { GenerationList } from '../../components/generation-list';
import { GenerationStatus, IGeneration } from '../../store/api/admin/types/models';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useLocationParams } from '../../hooks/use-params';
import { useBindNavigator } from '../../hooks/use-navigator';

const b = block('gen-manage-page');


const GENERATIONS_DEFAULT_OFFSET = 10

export const GenManagePage: React.FC = () => {
  const {addToNavigateBar} = useBindNavigator<any>();
  const {params} = useLocationParams<any>(window.location.search);
  const {add} = useToaster();

  const [getGenerations, {
    data: partGenerations, 
    ...getGenerationsRequestInfo
  }] = useLazyGetGenerationsQuery();

  const [printTag, printTagRequestInfo] = usePrintTagMutation();

  const [receiveTag, receiveTagRequestInfo] = useReceiveTagMutation();

  const [generations, setGenerations] = React.useState<IGeneration[]>([]);
  const [genetaionsRequestOffset, setGenerationsRequestOffset] = React.useState<number>(GENERATIONS_DEFAULT_OFFSET);
  const [stopScrollActList, setStopScrollActList] = React.useState<boolean>(false);
  // const [dataTrigger, setDataTrigger] = React.useState("");

  const updateGeneration = (id: string, status: GenerationStatus) => {
    const updatedGenerations = [...generations];
    const gIndex = updatedGenerations.findIndex(ug => ug.id === id);
    if (gIndex !== -1) {
      const g = updatedGenerations[gIndex];
      updatedGenerations.splice(gIndex, 1, {...g, status: status})
    }
    setGenerations(updatedGenerations);
  }

  const handleOnScrollActList = async () => {
    if (stopScrollActList) return;
    // setDataTrigger("scroll");
    try {
      const r = await getGenerations({ 
        ...params, 
        offset: genetaionsRequestOffset 
      })
      
      if (r.status === QueryStatus.fulfilled && r.data) {
        setGenerations([...generations, ...r.data.result.generations])
        if (r.data.result.generations.length === GENERATIONS_DEFAULT_OFFSET) {
          setGenerationsRequestOffset(genetaionsRequestOffset + GENERATIONS_DEFAULT_OFFSET);
        } else {
          setStopScrollActList(true);
        }
      } 
    } catch {

    }
    // setDataTrigger("");
  }

  const handleOnActionBtnClick = (item: IGeneration) => {
    if (item.status === GenerationStatus.PRINTING) {
      receiveTag({generationId: item.id});
    } else if (
      item.status === GenerationStatus.GENERATED ||
      item.status === GenerationStatus.GENERATING
    ) {
      printTag({generationId: item.id});
    }
  }

  React.useEffect(() => {
    setGenerations([]);

    getGenerations({}).then(r => {
      setGenerations((r.data?.result.generations || []))
    });

    addToNavigateBar({
      ...params
    });
  }, [])

  React.useEffect(() => {
    if (printTagRequestInfo.isSuccess) {
      updateGeneration(printTagRequestInfo.data.result.id, printTagRequestInfo.data.result.status);
    }
  }, [printTagRequestInfo.isSuccess, printTagRequestInfo.data])

  React.useEffect(() => {
    if (receiveTagRequestInfo.isSuccess) {
      updateGeneration(receiveTagRequestInfo.data.result.id, receiveTagRequestInfo.data.result.status);
    }
  }, [receiveTagRequestInfo.isSuccess, receiveTagRequestInfo.data])

  React.useEffect(() => {
    if (getGenerationsRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: generateTagRequestInfo.error.error.message,
      });
    }
  }, [getGenerationsRequestInfo.isError, getGenerationsRequestInfo.error])

  React.useEffect(() => {
    if (printTagRequestInfo.isError || receiveTagRequestInfo.isError) {
      // @ts-ignore
      const errorMessage = printTagRequestInfo?.error?.error.message ||
      // @ts-ignore
      receiveTagRequestInfo?.error?.error.message
      
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: errorMessage,
      });
    }
  }, [
    printTagRequestInfo.isError, 
    printTagRequestInfo.error,
    receiveTagRequestInfo.isError,
    receiveTagRequestInfo.error
  ])

  return (
    <Layout>
      <Stack 
        py={2}
        direction='row' 
        justifyContent='center' 
        alignContent='center'
        bgcolor='#ffffff'
        borderRadius={3}
        mb={0.5}
      >
        <Box 
          width={600} 
          component='h1' 
          className="fs28-primary-bold"
        >
          Управление генерациями
        </Box>
      </Stack>
      <div className={b()}>
        <GenerationList 
          items={generations}
          isLoading={getGenerationsRequestInfo.isLoading}
          isScrollLoading={getGenerationsRequestInfo.isFetching}
          renderEmptyList={() => <>Нет данных</>}
          renderItem={(item: IGeneration) =>
            <GenManageCard 
              generation={item}
              onActionClick={() => handleOnActionBtnClick(item)}
            />
          }
          onScroll={handleOnScrollActList}
        />
        {/* <Stack 
          height='calc(100% - 32px)' 
          width='650px'
          spacing={2}
          overflow='auto'
          py={4}
          px={2}
        >
          {(generations?.result.generations || []).map(g => 
            <GenManageCard 
              generation={g}
            />
          )}
        </Stack> */}
      </div>
    </Layout>
  );
};