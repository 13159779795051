import React from 'react';
import block from 'bem-cn-lite';
import { Button, Icon, Label, TextInput, useToaster } from '@gravity-ui/uikit';

import './Qr.scss';
import {Layout} from '../../components/layout';
import { InputLabel } from '../../components/input-label';
import {ReactComponent as QrCountBtnIcon} from '../../assets/qr-count.svg';
import { Stack } from '@mui/material';
import { useGenerateTagMutation } from '../../store/api/admin';
import { isDigitOrEmpty } from '../../utils/validation';
import { CircleCheckFill, CircleXmarkFill } from '@gravity-ui/icons';

const b = block('qr-page');

const qrCountVariants = [10, 50, 100, 200];

export const QrPage: React.FC = () => {
  const {add} = useToaster();
  const [generateTag, generateTagRequestInfo] = useGenerateTagMutation();

  const [disableGenBtn, setDisableGenBtn] = React.useState(true);
  const [description, setDescription] = React.useState("");
  const [count, setCount] = React.useState("");

  const handleOnDescriptionChange = (e: any) => setDescription(e.target.value);
  const handleOnCountChange = (e: any) => {
    if (isDigitOrEmpty(e.target.value)) {
      setCount(e.target.value)
    }
  }

  const handleOnCountLabelClick = (count: number) => setCount(count.toString());

  const handleGenerateBtnClick = () => {
    generateTag({
      count: parseInt(count),
      description
    })
  }

  React.useEffect(() => {
    if (count && description) {
      setDisableGenBtn(false);
    } else {
      setDisableGenBtn(true);
    }
  }, [count, description])

  React.useEffect(() => {
    if (generateTagRequestInfo.isSuccess) {
      add({
        name: 'generate-tag-success',
        autoHiding: 3000,
        isClosable: true,
        title: `Сгенерировано ${count} QR-кодов`,
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
      });
      setCount("");
      setDescription("");
    }
  }, [generateTagRequestInfo.isSuccess])

  React.useEffect(() => {
    if (generateTagRequestInfo.isError) {
      add({
        name: 'generate-tag-success',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: generateTagRequestInfo.error.error.message,
      });
    }
  }, [generateTagRequestInfo.isError, generateTagRequestInfo.error])


  return (
    <Layout>
      <div className={b()}>
        <div className={b('qr-form')}>
          <h3 className='fs28-primary-bold'>
            Генерация QR-кодов
          </h3>
          <InputLabel labelText='Описание'>
            <TextInput 
              size='xl'
              placeholder='Короткое описание для генерации'
              value={description}
              onChange={handleOnDescriptionChange}
            />
          </InputLabel>
          <TextInput 
            size='xl'
            value={count}
            onChange={handleOnCountChange}
            placeholder='Введите количество'
            className={b('qr-count-input')}
            endContent={
              <Button 
                className={b('qr-count-input-btn')}
                view='action' 
                size='xl'
                disabled={disableGenBtn}
                loading={generateTagRequestInfo.isLoading}
                onClick={handleGenerateBtnClick}
              >
                Сгенерировать
                <QrCountBtnIcon />
              </Button>
            }
          />
          <Stack direction='row' spacing={1} mt={1}>
            {qrCountVariants.map(v =>
              <Label 
                interactive
                onClick={() => handleOnCountLabelClick(v)}
              >
                {v}
              </Label>
            )}
          </Stack>
        </div>
      </div>
    </Layout>
  );
};