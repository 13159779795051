import React from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, Label, Spin, Table, useToaster, withTableCopy} from '@gravity-ui/uikit';

import './Main.scss';
import {Layout} from '../../components/layout';

import {useLocationParams} from '../../hooks/use-params';

import { TagStatus } from '../../store/api/admin/types/models';
import { TagStatusGroupButton } from './ui/TagStatusGroupButton';
import { useBindNavigator } from '../../hooks/use-navigator';
import { CircleXmarkFill, QrCode } from '@gravity-ui/icons';
import { Box, Stack, TablePagination } from '@mui/material';
import { useLazyGetTagsQuery } from '../../store/api/admin';
import { TablePaginationActions } from '../../components/pagination';
import { formatDate } from '../../utils/date';

const b = block('main-page');

const TagTable = withTableCopy(Table);


const columns = [
  {
    id: 'tag',       
    name: "Метка",
    template: (item: any, _: number) => 
      <Stack 
        direction='row' 
        alignItems={'center'}
      >
        <Button style={{ marginRight: 4 }}>
          <Icon data={QrCode} />
        </Button> 
        {item.id.split('-').at(0)}
      </Stack>,
  },
  {
    id: 'owner',     
    name: "Владелец",
    template: (item: any, _: number) => item.ownerName || undefined,
  }, 
  {
    id: 'link',      
    name: "Ссылка",
    template: (item: any, _: number) => <a href={`${process.env.REACT_APP_BTAG_MOBILE_URL}/${item.id}`} target='_blank'>
      {`${process.env.REACT_APP_BTAG_MOBILE_URL}/${item.id}`}
    </a>,
    meta: {copy: (item: any) => `${process.env.REACT_APP_BTAG_MOBILE_URL}/${item.id}`}
  },
  {
    id: 'createdAt', 
    name: "Дата и время создания",
    template: (item: any, _: number) => formatDate(item.createdAt),
  },
  {
    id: 'expiresAt', 
    name: "Срок действия",
    template: (item: any, _: number) => item.expiresAt ? formatDate(item.expiresAt) : undefined,
  },
  {
    id: 'status',    
    name: "Статус",
    template: (item: any) => {
      switch (item.status) {
        case TagStatus.INACTIVE: return <Label theme='danger'>Не активна</Label>
        case TagStatus.CREATED: return <Label theme='unknown'>Создан</Label>
        case TagStatus.DEPOSITED: return <Label theme='success'>Выдан</Label>
        case TagStatus.EXPIRED: return <Label theme='warning'>Просрочен</Label>
        case TagStatus.STORED: return <Label theme='utility'>На хранении</Label>
        case TagStatus.PRINTING: return <Label theme='normal'>На печати</Label>
        default: return ""
      }
    },
  },
];

const TAG_ROWS_PER_PAGE = 10;

export const MainPage: React.FC = () => {
  const {add} = useToaster();
  const {addToNavigateBar, clearNavigatorBar} = useBindNavigator<any>();
  const {params} = useLocationParams<any>(window.location.search);

  const [getTags, {
    data, ...getTagsRequestInfo
  }] = useLazyGetTagsQuery();

  const [page, setPage] = React.useState(params.page ? parseInt(params.page) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(params.rows ? parseInt(params.rows) : TAG_ROWS_PER_PAGE);

  const [selectedTagStatus, setSelectedMarkStatus] = React.useState<TagStatus[]>(
    params.status ? Array.isArray(params.status) ? params.status : [params.status] : [TagStatus.CREATED],
  );

  const handleChangeSelectedMarkStatus = async (markStatus: TagStatus[]) => {
    setSelectedMarkStatus(markStatus);

    setPage(1);
    clearNavigatorBar({
      page: 1,
      rows: rowsPerPage,
      status: markStatus,
    });

    getTags({
      ...params, 
      status: markStatus
    });
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
    addToNavigateBar({
      ...params,
      page: newPage,
    });

    getTags({
      ...params, 
      limit: rowsPerPage,
      offset: newPage * rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const limit = parseInt(event.target.value, 10);
    setRowsPerPage(limit);
    setPage(1);
    addToNavigateBar({
      ...params,
      page: 1,
      rows: limit
    });

    getTags({
      ...params, 
      limit: limit,
      offset: 0
    });
  };


  React.useEffect(() => {

    getTags({
      ...params,
      status: params.status || selectedTagStatus,
      limit: parseInt(params.rows) || rowsPerPage,
      offset: (parseInt(params.page) - 1 || page - 1) * (parseInt(params.rows) || rowsPerPage),
    })

    addToNavigateBar({
      ...params,
      rows: params.rows || TAG_ROWS_PER_PAGE,
      page: params.page || page,
    });
  }, []);

  React.useEffect(() => {
    if (getTagsRequestInfo.isError) {
      add({
        name: 'generate-tag-success',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: getTagsRequestInfo.error.error.message,
      });
    }
  }, [getTagsRequestInfo.isError, getTagsRequestInfo.error])

  return (
    <Layout>
      <div className={b()}>
        <div className={b('acts-nav')}>
          <div className={b('group-btn')}>
            <TagStatusGroupButton
              status={selectedTagStatus}
              onChange={handleChangeSelectedMarkStatus}
            />
          </div>
        </div>

        <div className={b('table-container')}>
          <div className={b('table-wrapper')}>

            {getTagsRequestInfo.isFetching
              ? <Box className={b('loading')}>
                  <Spin />
                </Box>
              : null
            }
          
            <TagTable 
              data={data?.result.tags || []} 
              columns={columns} 
              className={b('tag-table')}
              width='max'
              emptyMessage='Данных нет'
            />

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={data?.result.totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={<p className='fs15-secondary-thin'>Строк на странице:</p>}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}–${to} из ${count !== -1 ? count : `more than ${to}`}` : ''}
              sx={{
                "& .MuiToolbar-root": {
                  p: 4
                },
                "& *": {
                  fontFamily: 'inherit',
                }
              }}
            />
            
          </div>
        </div>
      </div>

    </Layout>
  );
};
