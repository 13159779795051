import React from 'react';
import block from 'bem-cn-lite';
import {Skeleton} from '@gravity-ui/uikit';

import './GenerationList.scss';
import { IGeneration } from '../../store/api/admin/types/models';
import { CircularProgress } from '@mui/material';

const b = block('list-container');

interface GenerationListProps {
  items: IGeneration[];
  isLoading?: boolean;
  isScrollLoading?: boolean;
  renderItem: (item: IGeneration) => React.ReactNode;
  renderEmptyList: () => React.ReactNode;
  onScroll: () => void;
}

export const GenerationList: React.FC<GenerationListProps> = ({
  items,
  isLoading,
  isScrollLoading,
  onScroll,
  renderItem,
  renderEmptyList
}) => {

  const [listLastScrollTop, setListLastScrollTop] = React.useState<number>(0);
  
  const handlerOnScrollList = async (event: any) => {
    const scrollTop = event.target.scrollTop;
    const scrollTopMax = event.target.scrollHeight - event.target.clientHeight;
    
    if (scrollTop > listLastScrollTop) {
      setListLastScrollTop(scrollTop);
    } else if (scrollTop === 0) {
			setListLastScrollTop(scrollTop);
		} else {
      return;
    }

    if (scrollTopMax === scrollTop) {
      onScroll();
    }
  }

  return (
    <div className={b()} onScroll={handlerOnScrollList}>
      {isLoading
        ? <Skeleton className={b('skeleton')} />
        : null
      }
      {items.length === 0 ? (
        renderEmptyList()
      ) : (
        <>
          <div className={b('list')}>
            {items.map((item) => renderItem(item))}
          </div>
          {isScrollLoading
            ? <CircularProgress />
            : null
          }
        </>
      )}
    </div>
  );
};