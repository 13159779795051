import {AuthPage} from '../pages/auth';
import {Navigate, Route, Routes, Outlet} from 'react-router-dom';
import {MainPage} from '../pages/main';
import {NavigationPath} from '../utils/constant/navigation';
import { QrPage } from '../pages/qr';
import { GenManagePage } from '../pages/gen-manage';

const PrivateRoute = () => {
  const auth = localStorage.getItem('accessToken');
  return auth ? <Outlet /> : <Navigate to={NavigationPath.LoginPage} />;
};

const UnAuthRoute = () => {
  const auth = localStorage.getItem('accessToken');
  return !auth ? <Outlet /> : <Navigate to={NavigationPath.MarkPage} />;
};

export const Routing = () => {
  return (
    <Routes>
      <Route path={NavigationPath.LoginPage} element={<UnAuthRoute />}>
        <Route path={NavigationPath.LoginPage} element={<AuthPage />} />
      </Route>

      <Route path={NavigationPath.MarkPage} element={<PrivateRoute />}>
        <Route path={NavigationPath.MarkPage} element={<MainPage />} />
      </Route>

      <Route path={NavigationPath.QRPage} element={<PrivateRoute />}>
        <Route path={NavigationPath.QRPage} element={<QrPage />} />
      </Route>

      <Route path={NavigationPath.GenManagePage} element={<PrivateRoute />}>
        <Route path={NavigationPath.GenManagePage} element={<GenManagePage />} />
      </Route>

      <Route path={NavigationPath.Any} element={<PrivateRoute />}>
        <Route path={NavigationPath.Any} element={<Navigate to={NavigationPath.MarkPage} />} />
      </Route>
      
    </Routes>
  );
};
