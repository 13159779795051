import React from 'react';

import './GenManageCard.scss';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, Icon, Label } from '@gravity-ui/uikit';
import { ArrowUpFromSquare, ClockArrowRotateLeft, Printer, Boxes3 } from '@gravity-ui/icons';
import { GenerationStatus, IGeneration } from '../../store/api/admin/types/models';
import { formatDate } from '../../utils/date';

interface GenManageCardProps {
  generation: IGeneration,
  onActionClick: () => void,
}

export const GenManageCard: React.FC<GenManageCardProps> = ({
  generation,
  onActionClick
}) => {

  const getLabelStatus = () => {
    switch (generation.status) {
      case GenerationStatus.GENERATING: return <Label theme='warning'>Генерируется</Label>
      case GenerationStatus.GENERATED: return <Label theme='normal'>Сгенерировано</Label>
      case GenerationStatus.PRINTING: return <Label theme='info'>Отправлено на печать</Label>
      case GenerationStatus.RECEIVED: return <Label theme='success'>Передан в камеру хранения</Label>
      default: return ""
    }
  }

  const getButtonViewByStatus = () => {
    switch (generation.status) {
      case GenerationStatus.GENERATING: 
      case GenerationStatus.GENERATED: return 'outlined-info';
      case GenerationStatus.PRINTING: return 'outlined-success';
      default: return 'outlined';
    }
  }

  const handleOnActionBtnClick = () => {
    onActionClick()
  }

  return (
    <Box p={2} border='1px solid #0000001A' borderRadius={2} width={620} mb={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} mb={1}>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
          <Button size='xl'>
            <Icon data={ClockArrowRotateLeft} size={20} />
          </Button>
          <Stack direction='column' justifyContent='space-between'>
            <span className='fs15-primary-bold'>
              {formatDate(generation.createdAt)} &nbsp; 
              <span className='fs15-secondary-thin'>
                {generation.count} QR-кодов
              </span>
            </span>
            <span className='fs13-secondary-thin'>{generation.id}</span>
          </Stack>
        </Stack>
        
        {getLabelStatus()}
      </Stack>
      <span className='fs13-secondary-thin'>
        {generation.description}
      </span>
      <Stack direction='row' justifyContent='space-between' spacing={1} mt={1}>
        <a 
          target='_blank'
          href={generation.link || undefined} 
          style={{ width: '100%' }} 
        >
          <Button size='l' width='max' disabled={!Boolean(generation.link)}>
            Скачать архив
            <Icon data={ArrowUpFromSquare} />
          </Button>
        </a>
        {generation.status !== GenerationStatus.RECEIVED
          ? <Button 
              size='l' 
              width='max'
              onClick={handleOnActionBtnClick}
              view={getButtonViewByStatus()} 
            >
              {generation.status === GenerationStatus.PRINTING ? "Отправить на хранение" : "Передать на печать"}
              <Icon data={generation.status === GenerationStatus.PRINTING ? Boxes3 : Printer} />
            </Button>
          : null
        }
      </Stack>
    </Box>
  );
};
