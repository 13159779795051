export enum TagStatus {
  INACTIVE = 'INACTIVE',
  CREATED = 'CREATED',
  PRINTING = 'PRINTING',
  STORED = 'STORED',
  DEPOSITED = 'DEPOSITED',
  EXPIRED = 'EXPIRED',
}

export enum GenerationStatus {
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  PRINTING = 'PRINTING',
  RECEIVED = 'RECEIVED',
}

export interface IGeneration {
  id: string,
  status: GenerationStatus,
  count: number,
  description: string,
  createdAt: string,
  ownerName: string,
  link: string | null,
}

export interface ITag {
  id: string,
  status: TagStatus,
  createdAt: string,
  createdBy: string,
  expiresAT: string | null,
}